<template>
  <div v-if="isMountedRef" class="container pt-5">
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center mt-5"
    >
      <div class="text-center md:pt-[100px]">
        <h1 class="font-bold mb-5">{{ t("error.500_title") }}</h1>
        <p>{{ t("error.500_description") }}</p>
      </div>
      <div>
        <img
          src="/images/404.png"
          class="mb-5 max-w-[540px] w-auto"
          alt="500"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import type { NuxtError } from "#app";

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: () => ({}),
  },
});

const isMountedRef = ref(false);

const { t } = useI18n();

onMounted(() => {
  isMountedRef.value = true;

  Sentry.captureMessage("500 error page displayed", {
    level: "error",
    extra: {
      errorData: props.error,
      key: "ER-SPQ5",
    },
  });
});
</script>
